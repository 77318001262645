import React, { Fragment } from 'react'
import { Navbar, Nav } from 'react-bootstrap'

import Form from 'react-bootstrap/Form'

import { InstanceName } from 'components/Login'
import { PageProps, navigate } from 'gatsby'

import { parse } from 'query-string'
import { resetPassword, updateMyPassword } from 'API/user'
import { toast } from 'react-toastify'

const Image = '/forgot_password.svg'

const ResetPassword: React.FC<PageProps> = ({ location: { search } }) => {
	const [{ password, confirmPassword, token }, setData] = React.useState({
		password: '',
		confirmPassword: '',
		token: '',
	})

	React.useEffect(() => {
		const { token } = parse(search) as { token?: string }
		if (token) setData((data) => ({ ...data, token }))
	}, [search])

	const [modalData, setModalData] = React.useState<{
		password: string
		confirmPassword: string
	}>({
		password: '',
		confirmPassword: '',
	})

	const handleChange = (e: { target: { value: string; name: string } }) => {
		setModalData((state) => ({ ...state, [e.target.name]: e.target.value }))
		console.log({})
	}
	const handleSubmit = async () => {
		if (modalData.confirmPassword !== modalData.password) {
			alert("Passwords don't match")
			return
		}
		const data = await updateMyPassword(modalData)
		if (data) alert('Password changed')
		window !== undefined && window.location.reload()
	}

	return (
		<Fragment>
			<Navbar bg='white' className='navbarMail px-lg-5' expand='lg'>
				<Navbar.Brand className='navbarBrand' href='/'>
					<InstanceName></InstanceName>
				</Navbar.Brand>
			</Navbar>

			<div className='email-container'>
				<div className='row px-md-2 justify-content-center row-emailContent'>
					<div className='col-md-6 col-emailContent'>
						<h3>Reset Password</h3>
						<p className='mb-2'>Please enter your password to continue</p>
						<form
							onSubmit={async (e) => {
								e.preventDefault()
								handleSubmit()
							}}
						>
							<Form.Group controlId='formBasicEmail'>
								<Form.Control
									onChange={handleChange}
									type='password'
									placeholder='Enter password'
									value={password}
									className='input-field'
								/>
							</Form.Group>
							<Form.Group controlId='formBasicEmail'>
								<Form.Control
									onChange={handleChange}
									type='password'
									placeholder='Confirm password'
									value={confirmPassword}
									className='input-field'
								/>
							</Form.Group>
							<input type='submit' value='Change Password' className='btn btn-submit btn-primary rounded mt-2' />
						</form>

						<Nav.Link className='navLink' href='/'>
							<i className='bx bx-chevron-left'></i> Back to login
						</Nav.Link>
					</div>
					<div className='col-md-6 order-first order-md-last emailImg'>
						<div className='text-center'>
							<img src={Image} alt='' />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default ResetPassword
